<template>
  <div class="middleware-container">
    <reload-button
      v-show="activeTab === 'overview'"
      @click="overviewRefresh"
      :show-text="false"
      style="position: absolute; right: 16px; top: 13px; z-index: 999"
    ></reload-button>
    <a-tabs v-model="activeTab" :animated="false">
      <a-tab-pane key="overview" tab="总览">
        <a-row :gutter="16">
          <!-- 设备统计 -->
          <a-col :xs="24" :md="12" :xl="6" :xxl="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 348px">
              <div class="module-header" style="margin-bottom: 16px">
                设备状态统计
              </div>
              <status-card
                source-type="middleware"
                :countFunc="countFunc"
                @changeList="getParams({})"
                @filterByStatus="v => getParams({status: v})"
                @filterAlerts="v => currentSeverity = v"
              >
                <template slot="typeImg">
                  <img
                    src="~@/assets/icons/middleware.svg"
                    width="48"
                    height="48"
                  />
                </template>
              </status-card>
            </div>
          </a-col>
          <!-- 类型分布 -->
          <a-col :xs="24" :md="12" :xl="6" style="margin-bottom: 16px">
            <div class="module-container" style="height: 348px">
              <div class="module-header" style="margin-bottom: 16px">
                类型分布图表
              </div>
              <a-spin :spinning="spinning['typeChart']">
                <stacked-column-chart
                  v-if="typeChartData.length"
                  :height="270"
                  chart-id="middleware-type-chart"
                  :chart-data="typeChartData"
                  :is-severity-chart="true"
                  :size="size"
                ></stacked-column-chart>
                <div v-else style="height: 270px; position: relative; overflow: hidden;">
                  <empty-component
                    :imgUrl="require('@/assets/images/info_empty.png')"
                    :body-style="{
                      height: '168px',
                      marginTop: '44px'
                    }"
                    :description-style="{
                      marginTop: '-8px',
                      marginRight: '8px'
                    }"
                    description="暂无制造商分布数据"
                  ></empty-component>
                </div>
              </a-spin>
            </div>
          </a-col>
          <a-col :xs="24" :md="24" :xl="12" style="margin-bottom: 16px">
            <div class="module-container" style="height: 348px">
              <div class="module-header" style="margin-bottom: 16px">
                类型分布统计
              </div>
              <a-spin :spinning="spinning['typeChart']">
                <resource-tab
                  v-if="typeTabData.length && !spinning.typeChart"
                  source-type="middleware"
                  @goTable="v => getParams({middleware_type: v})"
                  @goTableWithStatus="v => getParams({middleware_type: v[0], status: v[1]})"
                  :dataSource="typeTabData"
                ></resource-tab>
                <div v-else style="height: 270px; position: relative; overflow: hidden;">
                  <empty-component
                    :imgUrl="require('@/assets/images/info_empty.png')"
                    :body-style="{
                      height: '168px',
                      marginTop: '44px'
                    }"
                    :description-style="{
                      marginTop: '-8px',
                      marginRight: '8px'
                    }"
                    description="暂无制造商分布数据"
                  ></empty-component>
                </div>
              </a-spin>
            </div>
          </a-col>
          <!-- 告警 -->
          <a-col :md="24" :lg="14" :xl="16" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header" style="margin-bottom: 16px">
                当前告警
              </div>
              <severity-radio-group
                style="position: absolute; top: 16px; right: 20px"
                v-model="currentSeverity"
              ></severity-radio-group>
              <alert-timeline
                :severity="currentSeverity"
                sourceType="middleware"
                style="padding: 10px 4px 0 10px; overflow: auto; height: 265px"
                class="overflow-scroll-hidden"
              ></alert-timeline>
            </div>
          </a-col>
          <a-col :md="24" :lg="10" :xl="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 340px">
              <div class="module-header">累计告警统计</div>
              <div style="position: absolute; right: 20px; top: 17px">
                <simple-select
                  :options="datetimeOptions"
                  :value="datetimeOptions[1]"
                  @input="v => (selectedDatetime = v.value)"
                ></simple-select>
              </div>
              <a-spin :spinning="spinning['alert']">
                <radial-bar-chart
                  v-if="chartData.length"
                  chart-id="middleware-severity-chart"
                  :chart-data="chartData"
                  legendPosition="right"
                  :flip-page="false"
                  :maxValue="null"
                  :height="270"
                  unit=""
                  :barSize="11"
                  :colors="severityColors"
                  :legendPositionCenter="true"
                  :annotationHtml="annotationHtml"
                  :style="{
                    paddingTop: '26px',
                    maxHeight: '270px',
                    overflow: 'hidden'
                  }"
                ></radial-bar-chart>
                <div v-else style="height: 270px; position: relative; overflow: hidden;">
                  <empty-component
                    :body-style="{
                      height: '216px',
                      marginTop: '36px'
                    }"
                    :description-style="{
                      marginTop: '-28px'
                    }"
                  ></empty-component>
                </div>
              </a-spin>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="list" tab="列表详情">
        <div
          style="
            background: #fff;
            border-radius: 8px;
            padding: 24px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
            position: relative;
          "
        >
          <middleware-table
            :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
            :fetch-params="fetchParams"
          ></middleware-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="auto" tab="自动发现列表">
         <div
          style="
            background: #fff;
            border-radius: 8px;
            padding: 24px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
            position: relative;
          "
        >
          <middleware-table
            :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
            :fetch-params="autoParams"
          ></middleware-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import moment from 'moment'
import {
  middlewareTypes,
  blueColor,
  redColor,
  orangeColor
} from '@/utils/const'
import {
  getMiddlewareAlertCount,
  getMiddlewareCount
} from '@/api/middleware'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import RadialBarChart from '@/components/chart/RadialBarChart'
import ResourceTab from '@/components/source-overview-page/ResourceTab'
import StackedColumnChart from '@/components/chart/StackedColumnChart'
import ReloadButton from '@/components/button/ReloadButton.vue'
import SimpleSelect from '@/components/select/SimpleSelect'

const imgUrl = require('@/assets/icons/alarm.svg')

export default {
  name: 'Middleware',
  components: {
    MiddlewareTable: () => import('@/components/table/MiddlewareTable/index.vue'),
    AlertTimeline: () => import('@/components/timeline/AlertTimeline.vue'),
    StatusCard: () => import('@/components/source-overview-page/StatusCard.vue'),
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    SeverityRadioGroup,
    RadialBarChart,
    ResourceTab,
    StackedColumnChart,
    ReloadButton,
    SimpleSelect
  },
  computed: {
    tabList () {
      const arr = [{ title: '全部', value: 'all' }]
      middlewareTypes.forEach(value => {
        arr.push({ value, title: this.$t(`middleware_type.${value}`) })
      })
      return arr
    },
    size () {
      if (this.middlewareTypes.length < 5) return 34
      if (this.middlewareTypes.length < 10) return 22
      if (this.middlewareTypes.length < 15) return 16
      return 12
    }
  },
  inject: ['reload'],
  data () {
    return {
      middlewareType: '',
      activeTab: 'overview',
      currentSeverity: 'all',
      countFunc: getMiddlewareCount,
      middlewareTypes,
      chartData: [],
      severityColors: [redColor, orangeColor, blueColor, '#38D3FA'],
      annotationHtml: `<div style="transform:translate(-50%,-50%); opacity: 0.75"><img width="46" height="46" src=${imgUrl}></div>`,
      typeChartData: [],
      datetimeOptions: [
        { label: '全部', value: 'all' },
        { label: '近 7 天', value: 'week' },
        { label: '近 30 天', value: 'month' }
      ],
      selectedDatetime: 'week',
      typeTabData: middlewareTypes.map(type => {
        return {
          name: type,
          status: [
            { name: 'normal', value: 0 },
            { name: 'abnormal', value: 0 },
            { name: 'alert', value: 0 },
            { name: 'unknown', value: 0 }
          ],
          total: 0
        }
      }),
      fetchParams: {},
      autoParams: { discovered: true },
      spinning: {
        typeChart: false,
        alert: false
      }
    }
  },
  mounted () {
    this.fetch()
    this.fetchAlerts()
  },
  methods: {
    fetch () {
      this.spinning.typeChart = true
      const arr = []
      Promise.all(middlewareTypes.map(type =>
        getMiddlewareCount({ count_of: 'status', middleware_type: type }).then(res => {
          const status = res.data.data
          let total = 0
          const index = this.typeTabData.findIndex(item => item.name === type)
          if (index !== -1) {
            this.typeTabData[index].status = status
            status.forEach(e => {
              total += e.value
              arr.push({
                name: this.$t(`middleware_type.${type}`),
                type: this.$t(`source_status.${e.name}`),
                value: e.value
              })
            })
            this.typeTabData[index].total = total
          }
        })
      )).then(() => { this.typeChartData = arr }).finally(() => { this.spinning.typeChart = false })
    },
    fetchAlerts (datetimes = [moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'), moment().format('YYYY-MM-DD HH:mm')]) {
      this.spinning.alert = true
      const params = {
        count_of: 'severity'
      }
      if (datetimes !== false) {
        params.datetime_from = datetimes[0]
        params.datetime_to = datetimes[1]
      }
      getMiddlewareAlertCount(params).then(res => {
        const arr = [
          { name: 'disaster', value: 0 },
          { name: 'high', value: 0 },
          { name: 'average', value: 0 },
          { name: 'warning', value: 0 }
        ]
        if (res.data.data && res.data.data.length) {
          for (let i = 0; i < res.data.data.length; i++) {
            const item = res.data.data[i]
            const index = arr.findIndex(alert => alert.name === item.name)
            if (index !== -1) arr[index].value = item.value
          }
        }
        this.chartData = arr.map(item => {
          return {
            name: this.$t(`alert_severity.${item.name}`),
            value: item.value
          }
        })
      }).finally(() => { this.spinning.alert = false })
    },
    getParams (params) {
      this.fetchParams = params
      this.activeTab = 'list'
    },
    overviewRefresh () {
      this.reload()
    }
  },
  watch: {
    selectedDatetime (v) {
      if (v === 'month') {
        this.fetchAlerts([moment().subtract(29, 'day').format('YYYY-MM-DD HH:mm'), moment().format('YYYY-MM-DD HH:mm')])
      } else if (v === 'all') {
        this.fetchAlerts(false)
      } else {
        this.fetchAlerts()
      }
    }
  }
}
</script>

<style lang="less">
.middleware-container {
  padding: 0 6px;

  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  }
}
</style>
